"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEventOccurrences200ResponseToJSON = exports.ListEventOccurrences200ResponseFromJSONTyped = exports.ListEventOccurrences200ResponseFromJSON = exports.instanceOfListEventOccurrences200Response = void 0;
var runtime_1 = require("../runtime");
var EventOccurrence_1 = require("./EventOccurrence");
var ListEventOccurrences200ResponseMeta_1 = require("./ListEventOccurrences200ResponseMeta");
/**
 * Check if a given object implements the ListEventOccurrences200Response interface.
 */
function instanceOfListEventOccurrences200Response(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListEventOccurrences200Response = instanceOfListEventOccurrences200Response;
function ListEventOccurrences200ResponseFromJSON(json) {
    return ListEventOccurrences200ResponseFromJSONTyped(json, false);
}
exports.ListEventOccurrences200ResponseFromJSON = ListEventOccurrences200ResponseFromJSON;
function ListEventOccurrences200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'next': !(0, runtime_1.exists)(json, 'next') ? undefined : json['next'],
        'previous': !(0, runtime_1.exists)(json, 'previous') ? undefined : json['previous'],
        'results': !(0, runtime_1.exists)(json, 'results') ? undefined : (json['results'].map(EventOccurrence_1.EventOccurrenceFromJSON)),
        'meta': !(0, runtime_1.exists)(json, 'meta') ? undefined : (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaFromJSON)(json['meta']),
    };
}
exports.ListEventOccurrences200ResponseFromJSONTyped = ListEventOccurrences200ResponseFromJSONTyped;
function ListEventOccurrences200ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : (value.results.map(EventOccurrence_1.EventOccurrenceToJSON)),
        'meta': (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaToJSON)(value.meta),
    };
}
exports.ListEventOccurrences200ResponseToJSON = ListEventOccurrences200ResponseToJSON;
