"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardGuideBylineToJSON = exports.CardGuideBylineFromJSONTyped = exports.CardGuideBylineFromJSON = exports.instanceOfCardGuideByline = void 0;
var runtime_1 = require("../runtime");
var CardGuideBylineAuthor_1 = require("./CardGuideBylineAuthor");
/**
 * Check if a given object implements the CardGuideByline interface.
 */
function instanceOfCardGuideByline(value) {
    var isInstance = true;
    isInstance = isInstance && "author" in value;
    return isInstance;
}
exports.instanceOfCardGuideByline = instanceOfCardGuideByline;
function CardGuideBylineFromJSON(json) {
    return CardGuideBylineFromJSONTyped(json, false);
}
exports.CardGuideBylineFromJSON = CardGuideBylineFromJSON;
function CardGuideBylineFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'author': (0, CardGuideBylineAuthor_1.CardGuideBylineAuthorFromJSON)(json['author']),
        'color': !(0, runtime_1.exists)(json, 'color') ? undefined : json['color'],
        'size': !(0, runtime_1.exists)(json, 'size') ? undefined : json['size'],
        'headline': !(0, runtime_1.exists)(json, 'headline') ? undefined : json['headline'],
        'text': !(0, runtime_1.exists)(json, 'text') ? undefined : json['text'],
    };
}
exports.CardGuideBylineFromJSONTyped = CardGuideBylineFromJSONTyped;
function CardGuideBylineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'author': (0, CardGuideBylineAuthor_1.CardGuideBylineAuthorToJSON)(value.author),
    };
}
exports.CardGuideBylineToJSON = CardGuideBylineToJSON;
