"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectTeaserMassInfoInnerToJSON = exports.ObjectTeaserMassInfoInnerFromJSONTyped = exports.ObjectTeaserMassInfoInnerFromJSON = exports.instanceOfObjectTeaserMassInfoInner = void 0;
/**
 * Check if a given object implements the ObjectTeaserMassInfoInner interface.
 */
function instanceOfObjectTeaserMassInfoInner(value) {
    var isInstance = true;
    isInstance = isInstance && "content" in value;
    return isInstance;
}
exports.instanceOfObjectTeaserMassInfoInner = instanceOfObjectTeaserMassInfoInner;
function ObjectTeaserMassInfoInnerFromJSON(json) {
    return ObjectTeaserMassInfoInnerFromJSONTyped(json, false);
}
exports.ObjectTeaserMassInfoInnerFromJSON = ObjectTeaserMassInfoInnerFromJSON;
function ObjectTeaserMassInfoInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'content': json['content'],
    };
}
exports.ObjectTeaserMassInfoInnerFromJSONTyped = ObjectTeaserMassInfoInnerFromJSONTyped;
function ObjectTeaserMassInfoInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'content': value.content,
    };
}
exports.ObjectTeaserMassInfoInnerToJSON = ObjectTeaserMassInfoInnerToJSON;
