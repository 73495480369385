"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListGuidePages200ResponseToJSON = exports.ListGuidePages200ResponseFromJSONTyped = exports.ListGuidePages200ResponseFromJSON = exports.instanceOfListGuidePages200Response = void 0;
var runtime_1 = require("../runtime");
var CardGuide_1 = require("./CardGuide");
/**
 * Check if a given object implements the ListGuidePages200Response interface.
 */
function instanceOfListGuidePages200Response(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListGuidePages200Response = instanceOfListGuidePages200Response;
function ListGuidePages200ResponseFromJSON(json) {
    return ListGuidePages200ResponseFromJSONTyped(json, false);
}
exports.ListGuidePages200ResponseFromJSON = ListGuidePages200ResponseFromJSON;
function ListGuidePages200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'next': !(0, runtime_1.exists)(json, 'next') ? undefined : json['next'],
        'previous': !(0, runtime_1.exists)(json, 'previous') ? undefined : json['previous'],
        'results': !(0, runtime_1.exists)(json, 'results') ? undefined : (json['results'].map(CardGuide_1.CardGuideFromJSON)),
    };
}
exports.ListGuidePages200ResponseFromJSONTyped = ListGuidePages200ResponseFromJSONTyped;
function ListGuidePages200ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : (value.results.map(CardGuide_1.CardGuideToJSON)),
    };
}
exports.ListGuidePages200ResponseToJSON = ListGuidePages200ResponseToJSON;
