"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardGuideImageToJSON = exports.CardGuideImageFromJSONTyped = exports.CardGuideImageFromJSON = exports.instanceOfCardGuideImage = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CardGuideImage interface.
 */
function instanceOfCardGuideImage(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCardGuideImage = instanceOfCardGuideImage;
function CardGuideImageFromJSON(json) {
    return CardGuideImageFromJSONTyped(json, false);
}
exports.CardGuideImageFromJSON = CardGuideImageFromJSON;
function CardGuideImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'width': !(0, runtime_1.exists)(json, 'width') ? undefined : json['width'],
        'height': !(0, runtime_1.exists)(json, 'height') ? undefined : json['height'],
        'renditions': !(0, runtime_1.exists)(json, 'renditions') ? undefined : json['renditions'],
        'fileSize': !(0, runtime_1.exists)(json, 'file_size') ? undefined : json['file_size'],
        'focal': !(0, runtime_1.exists)(json, 'focal') ? undefined : json['focal'],
        'caption': !(0, runtime_1.exists)(json, 'caption') ? undefined : json['caption'],
        'alt': !(0, runtime_1.exists)(json, 'alt') ? undefined : json['alt'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'schema': !(0, runtime_1.exists)(json, 'schema') ? undefined : json['schema'],
    };
}
exports.CardGuideImageFromJSONTyped = CardGuideImageFromJSONTyped;
function CardGuideImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.CardGuideImageToJSON = CardGuideImageToJSON;
