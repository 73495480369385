"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEvents200ResponseToJSON = exports.ListEvents200ResponseFromJSONTyped = exports.ListEvents200ResponseFromJSON = exports.instanceOfListEvents200Response = void 0;
var runtime_1 = require("../runtime");
var EventCard_1 = require("./EventCard");
var ListEventOccurrences200ResponseMeta_1 = require("./ListEventOccurrences200ResponseMeta");
/**
 * Check if a given object implements the ListEvents200Response interface.
 */
function instanceOfListEvents200Response(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListEvents200Response = instanceOfListEvents200Response;
function ListEvents200ResponseFromJSON(json) {
    return ListEvents200ResponseFromJSONTyped(json, false);
}
exports.ListEvents200ResponseFromJSON = ListEvents200ResponseFromJSON;
function ListEvents200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'next': !(0, runtime_1.exists)(json, 'next') ? undefined : json['next'],
        'previous': !(0, runtime_1.exists)(json, 'previous') ? undefined : json['previous'],
        'results': !(0, runtime_1.exists)(json, 'results') ? undefined : (json['results'].map(EventCard_1.EventCardFromJSON)),
        'meta': !(0, runtime_1.exists)(json, 'meta') ? undefined : (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaFromJSON)(json['meta']),
        'totalPages': !(0, runtime_1.exists)(json, 'total_pages') ? undefined : json['total_pages'],
        'currentPage': !(0, runtime_1.exists)(json, 'current_page') ? undefined : json['current_page'],
    };
}
exports.ListEvents200ResponseFromJSONTyped = ListEvents200ResponseFromJSONTyped;
function ListEvents200ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : (value.results.map(EventCard_1.EventCardToJSON)),
        'meta': (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaToJSON)(value.meta),
        'total_pages': value.totalPages,
        'current_page': value.currentPage,
    };
}
exports.ListEvents200ResponseToJSON = ListEvents200ResponseToJSON;
