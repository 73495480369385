"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectTeaserToJSON = exports.ObjectTeaserFromJSONTyped = exports.ObjectTeaserFromJSON = exports.instanceOfObjectTeaser = void 0;
var runtime_1 = require("../runtime");
var CardGuideImage_1 = require("./CardGuideImage");
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
var ObjectTeaserMassInfoInner_1 = require("./ObjectTeaserMassInfoInner");
/**
 * Check if a given object implements the ObjectTeaser interface.
 */
function instanceOfObjectTeaser(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "href" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "massInfo" in value;
    return isInstance;
}
exports.instanceOfObjectTeaser = instanceOfObjectTeaser;
function ObjectTeaserFromJSON(json) {
    return ObjectTeaserFromJSONTyped(json, false);
}
exports.ObjectTeaserFromJSON = ObjectTeaserFromJSON;
function ObjectTeaserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'label': json['label'],
        'text': json['text'],
        'href': json['href'],
        'image': (0, CardGuideImage_1.CardGuideImageFromJSON)(json['image']),
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
        'slug': json['slug'],
        'infoBox': !(0, runtime_1.exists)(json, 'info_box') ? undefined : json['info_box'],
        'infoBoxText': !(0, runtime_1.exists)(json, 'info_box_text') ? undefined : json['info_box_text'],
        'massInfo': (json['mass_info'].map(ObjectTeaserMassInfoInner_1.ObjectTeaserMassInfoInnerFromJSON)),
    };
}
exports.ObjectTeaserFromJSONTyped = ObjectTeaserFromJSONTyped;
function ObjectTeaserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'label': value.label,
        'text': value.text,
        'href': value.href,
        'image': (0, CardGuideImage_1.CardGuideImageToJSON)(value.image),
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value.location),
        'slug': value.slug,
        'mass_info': (value.massInfo.map(ObjectTeaserMassInfoInner_1.ObjectTeaserMassInfoInnerToJSON)),
    };
}
exports.ObjectTeaserToJSON = ObjectTeaserToJSON;
