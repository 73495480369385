"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEventOccurrences200ResponseMetaToJSON = exports.ListEventOccurrences200ResponseMetaFromJSONTyped = exports.ListEventOccurrences200ResponseMetaFromJSON = exports.instanceOfListEventOccurrences200ResponseMeta = void 0;
var runtime_1 = require("../runtime");
var ListEventOccurrences200ResponseMetaCategoriesInner_1 = require("./ListEventOccurrences200ResponseMetaCategoriesInner");
/**
 * Check if a given object implements the ListEventOccurrences200ResponseMeta interface.
 */
function instanceOfListEventOccurrences200ResponseMeta(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListEventOccurrences200ResponseMeta = instanceOfListEventOccurrences200ResponseMeta;
function ListEventOccurrences200ResponseMetaFromJSON(json) {
    return ListEventOccurrences200ResponseMetaFromJSONTyped(json, false);
}
exports.ListEventOccurrences200ResponseMetaFromJSON = ListEventOccurrences200ResponseMetaFromJSON;
function ListEventOccurrences200ResponseMetaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categories': !(0, runtime_1.exists)(json, 'categories') ? undefined : (json['categories'].map(ListEventOccurrences200ResponseMetaCategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON)),
    };
}
exports.ListEventOccurrences200ResponseMetaFromJSONTyped = ListEventOccurrences200ResponseMetaFromJSONTyped;
function ListEventOccurrences200ResponseMetaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'categories': value.categories === undefined ? undefined : (value.categories.map(ListEventOccurrences200ResponseMetaCategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerToJSON)),
    };
}
exports.ListEventOccurrences200ResponseMetaToJSON = ListEventOccurrences200ResponseMetaToJSON;
