"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardGuideToJSON = exports.CardGuideFromJSONTyped = exports.CardGuideFromJSON = exports.instanceOfCardGuide = void 0;
var CardGuideByline_1 = require("./CardGuideByline");
var CardGuideImage_1 = require("./CardGuideImage");
/**
 * Check if a given object implements the CardGuide interface.
 */
function instanceOfCardGuide(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "href" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "byline" in value;
    return isInstance;
}
exports.instanceOfCardGuide = instanceOfCardGuide;
function CardGuideFromJSON(json) {
    return CardGuideFromJSONTyped(json, false);
}
exports.CardGuideFromJSON = CardGuideFromJSON;
function CardGuideFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'href': json['href'],
        'image': (0, CardGuideImage_1.CardGuideImageFromJSON)(json['image']),
        'category': json['category'],
        'byline': (0, CardGuideByline_1.CardGuideBylineFromJSON)(json['byline']),
    };
}
exports.CardGuideFromJSONTyped = CardGuideFromJSONTyped;
function CardGuideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'href': value.href,
        'image': (0, CardGuideImage_1.CardGuideImageToJSON)(value.image),
        'category': value.category,
        'byline': (0, CardGuideByline_1.CardGuideBylineToJSON)(value.byline),
    };
}
exports.CardGuideToJSON = CardGuideToJSON;
