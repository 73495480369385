"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEventOccurrences200ResponseMetaCategoriesInnerToJSON = exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped = exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON = exports.instanceOfListEventOccurrences200ResponseMetaCategoriesInner = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ListEventOccurrences200ResponseMetaCategoriesInner interface.
 */
function instanceOfListEventOccurrences200ResponseMetaCategoriesInner(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListEventOccurrences200ResponseMetaCategoriesInner = instanceOfListEventOccurrences200ResponseMetaCategoriesInner;
function ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON(json) {
    return ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped(json, false);
}
exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON = ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON;
function ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
    };
}
exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped = ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped;
function ListEventOccurrences200ResponseMetaCategoriesInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'slug': value.slug,
        'count': value.count,
    };
}
exports.ListEventOccurrences200ResponseMetaCategoriesInnerToJSON = ListEventOccurrences200ResponseMetaCategoriesInnerToJSON;
