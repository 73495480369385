import {
    Configuration,
    EventdatesApi,
    GuidesApi,
    ObjectsApi,
    SingulareventdatesApi,
} from 'sbr-api';

let basePath = 'https://www.visitstockholm.com';

if (typeof window !== 'undefined') {
    if (window.location.origin.match(/http:\/\/localhost/)) {
        basePath = 'https://stage.visitstockholm.com';
        // basePath = window.location.origin;
    } else if (
        window.location.origin.match(
            /(\.test:|stage.visitstockholm.com|stage.visitstockholm.se|www.visitstockholm.se)/
        )
    ) {
        basePath = window.location.origin;
    }
}

const configuration = new Configuration({ basePath: basePath });

let _abortControllers = [];

class AbortControllerMiddleware {
    type = null;

    constructor(type) {
        this.type = type;
    }

    pre = (context) => {
        context.init = {
            ...context.init,
            signal: this._getController().signal,
        };
        return context;
    };

    _getController = () => {
        if (
            !_abortControllers[this.type] ||
            _abortControllers[this.type].signal.aborted
        ) {
            _abortControllers[this.type] = new AbortController();
        }

        return _abortControllers[this.type];
    };
}

function _abortRequestsOfType(type) {
    _abortControllers[type].abort();
    _abortControllers[type] = new AbortController();
}

export const abortEventRequests = () => _abortRequestsOfType('event');
export const abortGuideRequests = () => _abortRequestsOfType('guide');
export const abortObjectRequests = () => _abortRequestsOfType('object');
export const abortSingularEventsRequests = () => _abortRequestsOfType('singularevents');

export const eventsApi = new EventdatesApi(configuration).withMiddleware(
    new AbortControllerMiddleware('event')
);
export const guidesApi = new GuidesApi(configuration).withMiddleware(
    new AbortControllerMiddleware('guide')
);
export const objectsApi = new ObjectsApi(configuration).withMiddleware(
    new AbortControllerMiddleware('object')
);

export const singularEventsApi = new SingulareventdatesApi(configuration).withMiddleware(
    new AbortControllerMiddleware('singularevents')
);