"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventOccurrenceToJSON = exports.EventOccurrenceFromJSONTyped = exports.EventOccurrenceFromJSON = exports.instanceOfEventOccurrence = void 0;
var runtime_1 = require("../runtime");
var EventOccurrenceCategoriesInner_1 = require("./EventOccurrenceCategoriesInner");
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
/**
 * Check if a given object implements the EventOccurrence interface.
 */
function instanceOfEventOccurrence(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "externalWebsiteUrl" in value;
    isInstance = isInstance && "callToActionUrl" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "venueName" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "modifiedAt" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;
    isInstance = isInstance && "categories" in value;
    return isInstance;
}
exports.instanceOfEventOccurrence = instanceOfEventOccurrence;
function EventOccurrenceFromJSON(json) {
    return EventOccurrenceFromJSONTyped(json, false);
}
exports.EventOccurrenceFromJSON = EventOccurrenceFromJSON;
function EventOccurrenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'externalWebsiteUrl': json['external_website_url'],
        'callToActionUrl': json['call_to_action_url'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'address': json['address'],
        'venueName': json['venue_name'],
        'zipCode': json['zip_code'],
        'city': json['city'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
        'createdAt': (new Date(json['created_at'])),
        'modifiedAt': (new Date(json['modified_at'])),
        'date': (new Date(json['date'])),
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'startTime': json['start_time'],
        'endTime': json['end_time'],
        'categories': (json['categories'].map(EventOccurrenceCategoriesInner_1.EventOccurrenceCategoriesInnerFromJSON)),
        'featuredImage': !(0, runtime_1.exists)(json, 'featured_image') ? undefined : json['featured_image'],
    };
}
exports.EventOccurrenceFromJSONTyped = EventOccurrenceFromJSONTyped;
function EventOccurrenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'external_website_url': value.externalWebsiteUrl,
        'call_to_action_url': value.callToActionUrl,
        'address': value.address,
        'venue_name': value.venueName,
        'zip_code': value.zipCode,
        'city': value.city,
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value.location),
        'created_at': (value.createdAt.toISOString()),
        'modified_at': (value.modifiedAt.toISOString()),
        'date': (value.date.toISOString().substring(0, 10)),
        'start_date': (value.startDate.toISOString().substring(0, 10)),
        'end_date': (value.endDate.toISOString().substring(0, 10)),
        'start_time': value.startTime,
        'end_time': value.endTime,
        'categories': (value.categories.map(EventOccurrenceCategoriesInner_1.EventOccurrenceCategoriesInnerToJSON)),
    };
}
exports.EventOccurrenceToJSON = EventOccurrenceToJSON;
