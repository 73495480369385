"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventCardToJSON = exports.EventCardFromJSONTyped = exports.EventCardFromJSON = exports.instanceOfEventCard = void 0;
var runtime_1 = require("../runtime");
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
/**
 * Check if a given object implements the EventCard interface.
 */
function instanceOfEventCard(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "callToActionUrl" in value;
    isInstance = isInstance && "callToActionLabel" in value;
    isInstance = isInstance && "externalWebsiteUrl" in value;
    isInstance = isInstance && "externalWebsiteUrlText" in value;
    isInstance = isInstance && "venueName" in value;
    isInstance = isInstance && "location" in value;
    return isInstance;
}
exports.instanceOfEventCard = instanceOfEventCard;
function EventCardFromJSON(json) {
    return EventCardFromJSONTyped(json, false);
}
exports.EventCardFromJSON = EventCardFromJSON;
function EventCardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'occurrenceId': !(0, runtime_1.exists)(json, 'occurrence_id') ? undefined : json['occurrence_id'],
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'startTime': !(0, runtime_1.exists)(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !(0, runtime_1.exists)(json, 'end_time') ? undefined : json['end_time'],
        'href': !(0, runtime_1.exists)(json, 'href') ? undefined : json['href'],
        'categories': !(0, runtime_1.exists)(json, 'categories') ? undefined : json['categories'],
        'category': !(0, runtime_1.exists)(json, 'category') ? undefined : json['category'],
        'size': !(0, runtime_1.exists)(json, 'size') ? undefined : json['size'],
        'address': json['address'],
        'callToActionUrl': json['call_to_action_url'],
        'callToActionLabel': json['call_to_action_label'],
        'externalWebsiteUrl': json['external_website_url'],
        'externalWebsiteUrlText': json['external_website_url_text'],
        'venueName': json['venue_name'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
    };
}
exports.EventCardFromJSONTyped = EventCardFromJSONTyped;
function EventCardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'start_date': (value.startDate.toISOString().substring(0, 10)),
        'end_date': (value.endDate.toISOString().substring(0, 10)),
        'address': value.address,
        'call_to_action_url': value.callToActionUrl,
        'call_to_action_label': value.callToActionLabel,
        'external_website_url': value.externalWebsiteUrl,
        'external_website_url_text': value.externalWebsiteUrlText,
        'venue_name': value.venueName,
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value.location),
    };
}
exports.EventCardToJSON = EventCardToJSON;
