"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardGuideBylineAuthorToJSON = exports.CardGuideBylineAuthorFromJSONTyped = exports.CardGuideBylineAuthorFromJSON = exports.instanceOfCardGuideBylineAuthor = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CardGuideBylineAuthor interface.
 */
function instanceOfCardGuideBylineAuthor(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCardGuideBylineAuthor = instanceOfCardGuideBylineAuthor;
function CardGuideBylineAuthorFromJSON(json) {
    return CardGuideBylineAuthorFromJSONTyped(json, false);
}
exports.CardGuideBylineAuthorFromJSON = CardGuideBylineAuthorFromJSON;
function CardGuideBylineAuthorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'href': !(0, runtime_1.exists)(json, 'href') ? undefined : json['href'],
        'text': !(0, runtime_1.exists)(json, 'text') ? undefined : json['text'],
        'isLocal': !(0, runtime_1.exists)(json, 'is_local') ? undefined : json['is_local'],
    };
}
exports.CardGuideBylineAuthorFromJSONTyped = CardGuideBylineAuthorFromJSONTyped;
function CardGuideBylineAuthorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.CardGuideBylineAuthorToJSON = CardGuideBylineAuthorToJSON;
