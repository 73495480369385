"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListObjectPages200ResponseToJSON = exports.ListObjectPages200ResponseFromJSONTyped = exports.ListObjectPages200ResponseFromJSON = exports.instanceOfListObjectPages200Response = void 0;
var runtime_1 = require("../runtime");
var ObjectTeaser_1 = require("./ObjectTeaser");
/**
 * Check if a given object implements the ListObjectPages200Response interface.
 */
function instanceOfListObjectPages200Response(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListObjectPages200Response = instanceOfListObjectPages200Response;
function ListObjectPages200ResponseFromJSON(json) {
    return ListObjectPages200ResponseFromJSONTyped(json, false);
}
exports.ListObjectPages200ResponseFromJSON = ListObjectPages200ResponseFromJSON;
function ListObjectPages200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'next': !(0, runtime_1.exists)(json, 'next') ? undefined : json['next'],
        'previous': !(0, runtime_1.exists)(json, 'previous') ? undefined : json['previous'],
        'results': !(0, runtime_1.exists)(json, 'results') ? undefined : (json['results'].map(ObjectTeaser_1.ObjectTeaserFromJSON)),
    };
}
exports.ListObjectPages200ResponseFromJSONTyped = ListObjectPages200ResponseFromJSONTyped;
function ListObjectPages200ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : (value.results.map(ObjectTeaser_1.ObjectTeaserToJSON)),
    };
}
exports.ListObjectPages200ResponseToJSON = ListObjectPages200ResponseToJSON;
