"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.23.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventOccurrenceCategoriesInnerToJSON = exports.EventOccurrenceCategoriesInnerFromJSONTyped = exports.EventOccurrenceCategoriesInnerFromJSON = exports.instanceOfEventOccurrenceCategoriesInner = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the EventOccurrenceCategoriesInner interface.
 */
function instanceOfEventOccurrenceCategoriesInner(value) {
    var isInstance = true;
    isInstance = isInstance && "slug" in value;
    return isInstance;
}
exports.instanceOfEventOccurrenceCategoriesInner = instanceOfEventOccurrenceCategoriesInner;
function EventOccurrenceCategoriesInnerFromJSON(json) {
    return EventOccurrenceCategoriesInnerFromJSONTyped(json, false);
}
exports.EventOccurrenceCategoriesInnerFromJSON = EventOccurrenceCategoriesInnerFromJSON;
function EventOccurrenceCategoriesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'slug': json['slug'],
    };
}
exports.EventOccurrenceCategoriesInnerFromJSONTyped = EventOccurrenceCategoriesInnerFromJSONTyped;
function EventOccurrenceCategoriesInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'slug': value.slug,
    };
}
exports.EventOccurrenceCategoriesInnerToJSON = EventOccurrenceCategoriesInnerToJSON;
